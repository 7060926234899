/***** Setup ******/

@font-face {
    font-family: Circular-Std-Book;
    src: url(../fonts/circular-std-book-cufonfonts-webfont/CircularStd-Book.woff) format("woff");
    /*font-weight: 400;*/
    /*font-style: normal*/
}

@font-face {
    font-family: Circular-Std-Bold;
    src: url(../fonts/circular-std-book-cufonfonts-webfont/CircularStd-Bold.woff) format("woff");
}

html, body {
    height: 100vh;
    font-size: .95rem;
}


/***** Specific elements - often used only once ****/


/* Closed by default: should point left */
.sidebar-toggle-button {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(icons/arrow-left-thin.svg);
}

.sidebar-toggle-button-close {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(icons/arrow-right-thin.svg);
}



.close-button-small-screen svg {
    float: bottom;
    display:block;
    width: 100%;
}

#profile1-small-logo-bottom {
    width: 24px;
}
#profile2-small-logo-bottom {
    width: 24px;
}
#profile3-small-logo-bottom {
    width: 24px;
}

#profile1-logo-container svg {
    width: 24px;
}
#profile2-logo-container svg {
    width: 24px;
}
#profile3-logo-container svg {
    width: 24px;
}

#top-overlay-input {
    margin-bottom: 1vh;
}

#sidebar-right-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0 0 0 0;
    z-index: 103;
    overflow: hidden;
}

#sidebar-right-container .btn-lg {
    font-size: 1rem;
    border-radius: 0px;
    padding-left: 2px;
    padding-right: 2px;
}

.electricity-box {
    /* For one readon or another, all the element need an explicit 'display: flex' on them */
    align-items: center;
    margin-left: 30%;
}

.map-container {
    flex-grow: 1;
    position: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    margin: 0 !important;
}

.map-container .row {
    flex-grow: 1;
}

.mapboxgl-ctrl-group > button {
    width: 30px;
    height: 30px;
}


.mobile-buttons .btn-lg {
    border-radius: 0px;
    font-size: 1rem;
}

/** Very generic stuff **/

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.padding {
    padding: 0 !important;
    margin: 1vh !important;
}

/*
.smooth-transition {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
*/

.pointer {
    cursor: pointer;
}

.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
}

/* Element on the upper-right of the map */
.upper-right-anchor {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
    margin: 0;
    z-index: 102;
}

.bottom-left-anchor {
    position: absolute;
    bottom: 5px;
    left: 5px;
    padding: 0;
    margin: 0;
    z-index: 102;
}

.image-button:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 4px;
    margin-left: 4px;
    vertical-align: text-top;
    background: transparent no-repeat center center;
}
/******* TO CLEAN UP - Probably contains lots of dead code *******/

.top-overlap-nav {
    height: 4em;
}

.instructions {
    background: white;
    color: #2D4959;
    padding-top: 0px;
    margin-top: 4vh;
}

.instructions .row {
    margin: 10px;
}

.instruction-icon {
    padding-left: 5px;
}


.route-instructions {
    overflow-y: hidden;
    list-style: none;
    overflow-x: hidden;
    height: 100%;
    overflow-y: auto;
}

.instructions-resume div {
    display: inline;
    flex-grow: 1;
    text-align: center;
}

.instructions-resume {
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    margin: 1rem;
}

.instructions-resume-electric div {
    display: inline;
    flex-grow: 1;
    text-align: center;
}

.instructions-resume-electric {
    display: flex;
    flex-direction: row;
    font-size: 1.1rem;
}

#sidebar-right-container .container-fluid {
    flex: 0 0 1%;
}

#sidebar-right-content {
    flex: 1 0 1%;
    display: flex;
    flex-direction: column;
}

#sidebar-right-container.hidden-sidebar {
    width: 0;
}

.hidden {
    display: none;
}

.bottom-right-floating-buttons {
    position: absolute;
    right: 10px;
    bottom: 20px;
    z-index: 102;
}

.bottom-left-floating-buttons {
    position: absolute;
    left: 10px;
    bottom: 20px;
    z-index: 102;
}

.bottom-center-floating-buttons {
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 102;
}

#btnAddToHomescreen {
    display: none;
}

#swapOriginDestination:before {
    background-image: url(icons/baseline-swap_vert-24px.svg);
}


.form-control {
    border: none;
    background-color: #FFFFFF;
    border-radius: 0;
}

.profile-explanation-icons {
    text-align: center;
}

.profile-explanation-icons div img {
    width: 60%;
    padding: 0;
    margin: 7%;
    background-color: #ffffff;
    border-radius: 90%;
}

.non-active {
    background-color: #ffffff !important;
    opacity: 0.3 !important
}

.profile-explanation-icons div {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.profile-explanation-icons div img {
    width: 110%;
}

.profile-explanation-icons div div .indicator {
    border-radius: 100%;
    background-color: white;
    height: 30%;
    position: absolute !important;
    top: 0;
    right: -40%;
    width: auto;
    padding: 8%;

}

.profile-explanation {
    font-family: Circular-Std-Book, sans-serif;
    padding-top: 20px;
    padding-bottom: 20px;
}

.profile-explanation h4 {
    font-family: Circular-Std-Bold, sans-serif;
}

.profile-explanation-overview {
    margin-left: 20px;
    margin-right: 20px;
}

.profile-explanation p {
    min-height: 20%;
    text-align: center;
}

.large-summary-icon {
    width: 50%;
    padding-bottom: 5%;
    padding-top: 5%

}

.route:hover path, .route:hover polyline {
    fill: white;
    stroke: white;
}

.route:hover circle {
    stroke: white;
}


.active svg path {
    fill: #ffffff;
    stroke: #ffffff;
}

.active svg polyline,
.active svg circle {
    stroke: #ffffff;
}

.active circle {
    stroke: white;
}


.right-addon {
    display: flex;
    align-items: center;
}


.bottom-left-floating-buttons button img {
    padding: 0 0 0 0;
    margin-right: 2px
}


li {
    border-bottom: 1px solid white;
    padding: .2rem;
}

ul {
    list-style-type: none;
    padding: 0;
}

.dropdown-menu {
    font-size: .85rem;
}

input[type="text"], textarea {
    outline: none;
}

#top-overlay-profile-buttons-mobile {
    display: none;
}

.card-body {
    padding-top: 0;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: #2D4959;
    background-color: #2D4959;
    border-color: #2D4959;
}

.right-addon {
    position: relative;
}


.left-addon img {
    left: 0;
}

.right-addon img {
    right: 0;
}

.left-addon input {
    padding-left: 30px;
}

.right-addon input {
    padding-right: 30px;
    font-size: .85em;
}

.startpoint-li {
    background: url("./icons/currentLocation_white.svg") no-repeat left 9px;
}

.endpoint-li {
    background: url("./icons/ic_location_on_black_24px.svg") no-repeat left 9px;
}

#clearInputFieldFromButton, #clearInputFieldToButton {
    display: none;
}

.profile-summary-explanation {
    width: 90%;
    padding: 10px;
    text-align: center;
}

.profile-summary {
    height: 100%;
}

.profile-summary svg {
    height: 25%;
    width: 25%;
}

.profile-summary svg path {
    fill: white;
    stroke: white;
}

.profile-summary svg polyline,
.profile-summary svg circle {
    stroke: white;
}

.profile-gpx-div {
    padding: 10px;
    width: 90%;
}

.profile-gpx-button {
    text-align: right;
    border: 0.3rem solid rgb(134, 154, 184);
    border-radius: .3rem;
    padding: 10px;

    position: relative;
    float: right;
}

.electricity path {
    fill: white;
    stroke: white;
}

.electricity-box svg {
    height: 15%;
    width: 15%;
    margin-right: 5px;
}

.time-electric {
    font-size: 0.6em;
}


.location-circle {
    height: 30px;
    width: 30px;
}


.location-circle-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.8rem;
    font-weight: 700;
}

.profile-paragraph {
    font-size: 1.5em
    
}


table tr td {
    vertical-align: middle !important;
}


.height-zero {
    height: 0;
    display: none;
}



.marker-text {
    display: flex;
    margin-left: 11px;
    margin-top: 5px;
}

.top-bar {
    padding-top: 10px;
    padding-bottom: 10px;
}

.logo img {
    height: 50px;
}

#sidebarHamburger::before {
    background-image: url(icons/ic_menu_black_24px.svg);
}

.mapboxgl-ctrl-attrib p {
    display: inline;
}

#edit-button-link img {
    height: 14px;
    
}

@media only screen and (max-width: 767px) {
    body {
        font-size: 0.95rem;
        line-height: 1.5;
    }

    .mapboxgl-ctrl-group > button {
        width: 25px;
        height: 25px;
    }

    #swap-origin-destination-button {
        top: 50%;
        position: relative;
    }

    .top-overlay {
        padding-bottom: 0;
        padding-top: 0;
    }

    #sidebar-right-container .btn-lg {
        font-size: unset;
        padding-left: 2px;
        padding-right: 2px;
    }

    .btn-export {
        font-size: 0;
        padding: 2px 2px 0 2px;
    }

    .card {
        margin-bottom: 50px;
    }

    .form-control {
        line-height: 1;
    }

    .w-100-md {
        width: 100% !important;
    }

    .margin-mobile {
        margin-bottom: 5px;
        padding-bottom: 2px;
    }

    .location-circle {
        height: 25px;
        width: 25px;
    }

    .mobile-buttons .btn-lg {
        font-size: 0.85rem;
    }

    .logo img {
        height: 40px;
    }

    .top-bar {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in {
        display: none;
    }

    .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
        display: none;
    }

    .mapboxgl-ctrl-icon.mapboxgl-ctrl-compass {
        display: none;
    }
}

@media only screen and (max-height: 400px) {
    .mobile-buttons {
        display: none;
    }
}

.height-50 {
    height: 50px;
}

.min-height-50 {
    min-height: 50px;
}

.min-height-70 {
    min-height: 70px;
}

.font-big {
    font-size: 1.5rem;
}