/*
Bootstrap-compatible toggles.

If a certain element should only show op on small (and smaller) screens, add class='sm-or-smaller' to it.

To show something between ranges, (e.g. large and medium, but not small, xs or extra large) use a nested div:

<div class="md-or-bigger">
    <div class="lg-or-smaller">
        ... contents ...
    </div>
</div>

*/

.landscape-only {
    display: none;
}

.landscape-hidden {
    display: unset;
}

@media screen and (orientation: landscape) {
    .landscape-only {
        display: unset;
    }

    .landscape-hidden {
        display: none;
    }
}

.xs-or-smaller {
    display: none;
}

.sm-or-smaller {
    display: none;
}

.md-or-smaller {
    display: none;
}

.lg-or-smaller {
    display: none;
}

.xl-or-smaller {
    display: none;
}

.xs-or-bigger {
    display: none;
}

.sm-or-bigger {
    display: none;
}

.md-or-bigger {
    display: none;
}

.lg-or-bigger {
    display: none;
}

.xl-or-bigger {
    display: none;
}

.xs-only {
    display: none;
}

.sm-only {
    display: none;
}

.md-only {
    display: none;
}

.lg-only {
    display: none;
}

.xl-only {
    display: none;
}

@media only screen and (min-width: 0px) {
    .xs-or-bigger {
        display: unset;
    }
}

@media only screen and (max-width: 575px) {
    .xs-or-smaller {
        display: unset;
    }

    .xs-only {
        display: unset;
    }
}

@media only screen and (min-width: 576px) {
    .sm-or-bigger {
        display: unset;
    }
}

@media only screen and (min-width: 576px)and (max-width: 767px) {
    .sm-only {
        display: unset;
    }
}

@media only screen and (max-width: 767px) {
    .sm-or-smaller {
        display: unset;
    }
}

@media only screen and (min-width: 768px) {
    .md-or-bigger {
        display: unset;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .md-only {
        display: unset;
    }
}

@media only screen and (max-width: 991px) {
    .md-or-smaller {
        display: unset;
    }
}

@media only screen and (min-width: 992px) {
    .lg-or-bigger {
        display: unset;
    }
}

@media only screen and (min-width: 992px)and (max-width: 1200px) {
    .lg-only {
        display: unset;
    }
}

@media only screen and (max-width: 1200px) {
    .lg-or-smaller {
        display: unset;
    }
}

@media only screen and (min-width: 1200px) {
    .xl-or-bigger {
        display: unset;
    }

    .xl-only {
        display: unset;
    }
}

@media only screen and (min-width: 0) {
    .xl-or-smaller {
        display: unset;
    }
}

